import React from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
//import { calculateEggCount } from "../utils/calculateEggCount";
//import UtilityButton from "./UtilityButton";

interface gradeEntry {
    shed: string;
    type: string;
    laying_date: string;
    supplier: string;
    qty: string;
    eggs_count: string;
    eggs_unit: string;
    floor_count: number;
    floor_unit: string;
}

const GradingInputRow = () => {

    //Set up the form for use
    const { register, control, watch, formState: { errors } } = useFormContext()

    //const { fields, append, remove } = useFieldArray({
    const { fields, remove } = useFieldArray({
        control,
        name: "eggs_recieved"
    });

console.log('eggs recieved')

    //Get today's date
    const time_now = new Date();
    var day = ("0" + time_now.getDate()).slice(-2);
    var month = ("0" + (time_now.getMonth() + 1)).slice(-2);
    var today = time_now.getFullYear() + "-" + (month) + "-" + (day)

    let values = watch();
    let total: number = 0;

    //Calculate amount of eggs recieved
    function sum(grade_entries: gradeEntry[]) {
        if (typeof grade_entries !== 'undefined') {

            grade_entries.forEach((row: gradeEntry) => {
                //if (typeof row.eggs_count !== 'undefined' && typeof row.eggs_unit !== 'undefined') { 
                    total += Number(row.qty);
                    //total += calculateEggCount(parseInt(row.eggs_count), row.eggs_unit.toLowerCase());
                //}
            });

            if (isNaN(total)) {
                total = 0;
            }
        }
        
        return total;
    }

    //const addRowClick = () => {
    //    if (fields.length < 5) {
    //        append({ "shed": "shed", "type": "type", "laying_date": "", "supplier":"n/a", "qty": "0", "eggs_unit":"Eggs" });
    //    }
    //};

    return (
    <>
            <div className="GradingInput">   
            <h4>Eggs recieved</h4>
            <div className="items">
                <div className="shed">
                    <label className="required">Shed</label>
                </div>

                <div className="type">
                    <label className="required">Type</label>
                </div>

                <div className="date">
                    <label className="required">Laying Date</label>   
                </div>

                <div className="supplier">
                    <label className="required">Supplier</label>
                </div>

                <div className="number"> 
                    <label className="required">Quantity</label>
                </div>
                        
                <div className="number"> 
                    <label className="required">Unit</label>
                </div>
                <div></div>
          
               
                    {fields.map((data, index) => {
                        console.log("control", control.defaultValuesRef.current.eggs_recieved[index].qty)
                return (
                <React.Fragment key={index}>
                <input type="hidden" {...register(`eggs_recieved.${index}.id` as const)} />
                <div className="shed">
                    <input type="text" {...register(`eggs_recieved.${index}.shed` as const, { required: true })} className={ errors?.['eggs_recieved']?.[index]?.['shed'] ? "highlight" : '' } />
                </div>
                
                <div className="type">
                    <input type="text" {...register(`eggs_recieved.${index}.type` as const, { required: true })} className={ errors?.['eggs_recieved']?.[index]?.['type'] ? "highlight" : '' } />
                </div>

                <div className="date">
                    <input defaultValue={today}  type="date" {...register(`eggs_recieved.${index}.date` as const, { required: true })} className={ errors?.['eggs_recieved']?.[index]?.['date'] ? "highlight" : '' } />    
                </div>

                <div className="supplier">
                <input type="text" {...register(`eggs_recieved.${index}.supplier` as const, { required: true })} className={ errors?.['eggs_recieved']?.[index]?.['supplier'] ? "highlight" : '' }  />
                </div>

                <div className="number"> 
                <input type="number" min="1" max={ `eggs_recieved.${index}.qty` } {...register(`eggs_recieved.${index}.qty` as const, { required: true })} className={ errors?.['eggs_recieved']?.[index]?.['qty'] ? "highlight" : '' }  />
                </div>

                <div className="number"> 
                    <select disabled {...register(`eggs_recieved.${index}.eggs_unit` as const)} >
                        <option value="Eggs">Eggs</option>
                        <option value="Dozen">Dozen</option>
                        <option value="Trays">Trays</option>
                    </select>
                </div>
                <div>
                {fields.length>1 && 
                    <button className="close" type="button" onClick={() => fields.length>1 ? remove(index) : {}}>x</button>
                }
                </div>
                </React.Fragment>
                    
                );
            })}
      
                </div>
                {errors.eggs_recieved && <span className="form_error">Please ensure all required fields are completed.</span>}
        </div>
        
        <span className="endnote">Your ungraded collections have been brought into the grading. You may remove collections from this grading by clicking the x.).</span>

        <div className="recievedTools">
                {/*<div className="addRowControl">
                <UtilityButton name="Add row" onClick={reset} />
                </div>*/}
            <div className="flex"></div>
            <div className="totals">
                <h4>Total:</h4>
                <span className="GradeTotal">{ sum(values.eggs_recieved) }</span>
                </div>
        </div>
    </>
    );
};

export default GradingInputRow;
