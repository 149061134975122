import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';

type Inputs = {
    search_date: string;
}

type EggsRecieved = {
    shed: string;
    type: string;
    date: string;
    supplier: string;
    qty: number;
    eggs_unit: string;
}

type EggsGraded = {
    best_before_date: string;
    brand: string;
    grade: string;
    qty: number;
    eggs_unit: string;
}
  
type GradeSession = {
    start_date: string;
    start_time: string;
    end_date: string;
    end_time: string;
    julian_date: number;
    eggs_recieved: EggsRecieved[];
    eggs_graded: EggsGraded[];
    waste_count: number;
    waste_unit: string;
    comment: string;
    graded_by: string;
};

const RecallReport = () => {

    const gradings = localStorage.getItem("gradings") || '{}';
    const { register, watch } = useForm<Inputs>();
    const search_date = watch("search_date", "");

    let gradingsArray: GradeSession[] = [];

    try {
        gradingsArray = JSON.parse(gradings);
    } catch (e) {
        gradingsArray = [];
    }

    if (gradingsArray.length > 0) {
        return (
            <div className="container">
                <h1>Recall report</h1>
                <form>
                    <h2>Product to recall</h2>
                    <p>This report allows you to enter the best before date captured on the egg carton of interest and trace your product back to the shed from which the eggs were produced. Start by entering the best before date below.</p>
                    <label>Egg Carton Best Before Date:</label>
                    <input className="resetLength" type="date" {...register("search_date", { required: true })} />

                    {gradingsArray.filter((obj, index, self) =>
                        obj.eggs_graded.some(itm => itm.best_before_date === search_date)
                    ).length > 0 &&
                        <>
                            <h3>Products</h3>
                            <p>The following table lists the products that are associated with this best before date.</p>

                            {gradingsArray.filter((obj, index, self) =>
                                obj.eggs_graded.some(itm => itm.best_before_date === search_date)
                            ).map((grade: GradeSession, index: number) =>
                                <div className="recallreport">
                                    <table>
                                        <tr className="productInfoTableRow productInfoTableTopRow">
                                            <td><span>Grading Date:</span></td>
                                            <td>{grade.start_date}</td>
                                            <td><span>Julian Date:</span></td>
                                            <td colSpan={2}>{grade.julian_date}</td>
                                        </tr>
                                        <tr className="productInfoTableRow">
                                            <td><span>Graded By:</span></td>
                                            <td colSpan={4}>{grade.graded_by}</td>
                                        </tr>
                                        <tr>
                                            <th>Best Before Date</th>
                                            <th colSpan={2}>Brand</th>
                                            <th>Grade/Size</th>
                                            <th>Quantity</th>
                                        </tr>
                                        {grade.eggs_graded.map((eggs: EggsGraded, index: number) =>
                                            <tr>
                                                <td>{eggs.best_before_date}</td>
                                                <td colSpan={2}>{eggs.brand}</td>
                                                <td>{eggs.grade}</td>
                                                <td>{eggs.qty}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <th>Shed</th>
                                            <th>Type</th>
                                            <th>Laying Date</th>
                                            <th>Supplier</th>
                                            <th>Qty</th>
                                        </tr>
                                        {grade.eggs_recieved.map((eggs: EggsRecieved, index: number) =>
                                            <tr>
                                                <td>{eggs.shed}</td>
                                                <td>{eggs.type}</td>
                                                <td>{eggs.date}</td>
                                                <td>{eggs.supplier}</td>
                                                <td>{eggs.qty}</td>
                                            </tr>
                                        )}
                                        <tr className="productInfoTableLastRow">
                                            <td colSpan={5}></td>
                                        </tr>
                                    </table>
                                </div>
                            )}
                        </>
                    }

                    {gradingsArray.filter((obj, index, self) =>
                        obj.eggs_graded.some(itm => itm.best_before_date === search_date)
                    ).length === 0 &&
                        <>
                            <p>There are no products matching this date.</p>
                        </>
                    }

                </form>
            </div>
        );
    } else {
        return (
            <div className="container">
                <h1>Recall report</h1>
                <form>
                    <h2>There have been no products graded and sold.</h2>
                    <p>You haven't completed any gradings yet. <Link to="/grade">You can enter a grading here.</Link></p>
                </form>
            </div>
        );
    }
    };

export default RecallReport;