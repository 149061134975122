import React from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { calculateEggCount } from "../utils/calculateEggCount";


import UtilityButton from "./UtilityButton";


interface gradeEntry {
    grade: string;
    qty: string;
    eggs_unit: string;
}


const GradingOutputRow = () => {

    //Get today's date
    const time_now = new Date();
    var day = ("0" + time_now.getDate()).slice(-2);
    var month = ("0" + (time_now.getMonth() + 1)).slice(-2);
    var today = time_now.getFullYear() + "-" + (month) + "-" + (day)

    const { register, control, watch, formState: { errors } } = useFormContext()

    const { fields, append, remove } = useFieldArray({
        control,
        name: "eggs_graded"
    });

    const addRowClick = () => {
        if (fields.length < 5) {
            append({ brand:"n/a", "grade":"AAA", "qty": 0, "eggs_unit":"Eggs" });
        }
    };

    let values = watch();
    let total: number = 0;

    //Calculate amount of eggs recieved
    function sum(values: gradeEntry[]) {
        if (typeof values !== 'undefined') {

            values.forEach((row: gradeEntry) => {
                total += calculateEggCount(parseInt(row.qty), row.eggs_unit.toLowerCase())
            });

        
            if (isNaN(total)) {
                total = 0;
            }
        }
        return total;
    }

    return (
        <>
        <div className="GradingOutput">
            <h4>Eggs Packed</h4>
            <p>Enter the details of the graded eggs below.</p>
            <div className="items">
                <div><label className="required">Best Before</label></div>
                <div><label className="required">Brand</label></div>
                <div><label className="required">Grade/Size</label></div>
                <div><label className="required">Quantity</label></div>
                <div><label className="required">Unit</label></div>
                <div></div>

                {fields.map((field, index) => {
                    return (
                        <React.Fragment key={index}>
                        <div className="date">
                            <input defaultValue={today}  type="date" {...register(`eggs_graded.${index}.best_before_date` as const, { required: true })} className={ errors?.['eggs_graded']?.[index]?.['best_before_date'] ? "highlight" : '' } />    
                        </div>
                        <div>
                            <input type="text" {...register(`eggs_graded.${index}.brand` as const, { required: true })} className={ errors?.['eggs_graded']?.[index]?.['brand'] ? "highlight" : '' }  />
                        </div>
                        <div>
                            <input type="text" {...register(`eggs_graded.${index}.grade` as const, { required: true })} className={ errors?.['eggs_graded']?.[index]?.['grade'] ? "highlight" : '' }  />
                        </div>

                        <div>
                            <input type="number" min="0" defaultValue="0" {...register(`eggs_graded.${index}.qty` as const, { required: true })} className={ errors?.['eggs_graded']?.[index]?.['qty'] ? "highlight" : '' }  />
                        </div>

                        <div> 
                            <select {...register(`eggs_graded.${index}.unit` as const)} >
                                <option value="eggs">Eggs</option>
                                <option value="dozen">Dozen</option>
                                <option value="trays">Trays</option>
                            </select>
                            </div>
                            <div>{index>0 && 
                        <button className="close" type="button" onClick={() => index > 0 ? remove(index) : {}}>x</button>
                        }</div>
                        </React.Fragment>
                );
                })}
            </div>
            {errors.eggs_recieved && <span className="form_error">Please ensure all required fields are completed.</span>}
            </div>

            <div className="outputTools">
                <div className="addRowControl">
                    <UtilityButton name="Add row" onClick={addRowClick} />
                </div>
                <div className="flex"></div>        
                <div className="totals">
                    <h4>Total:</h4>
                    <span className="GradeTotal">{ sum(values.eggs_graded) }</span>
                </div>
            </div>
            </>
    )
};

export default GradingOutputRow;