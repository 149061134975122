import { Link, useHistory } from 'react-router-dom';
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import SubmitFormButtons from './SubmitFormButtons';
import { useStateWithLocalStorage } from '../utils/useStateWithLocalStorage';
import SellContentRow from './SellContentRow';

const Sell = () => {

    type Inputs = {
        date: string,
        customer: string,
        invoice_number: string,
        po_number: string,
        comment: string,
        invoice_value: number
    };
    
    const history = useHistory();

    const time_now = new Date();
    var day = ("0" + time_now.getDate()).slice(-2);
    var month = ("0" + (time_now.getMonth() + 1)).slice(-2);
    var today = time_now.getFullYear() + "-" + (month) + "-" + (day)

    const { ...methods } = useForm(
        {
            defaultValues: {
                date: today,
                customer: "n/a",
                po_number: "n/a",
                invoice_number: "n/a",
                invoice_value: "0.00",
                comment: "",
                products: [{
                    brand: "n/a",
                    type: "AAA",
                    qty: 0,
                    unit: "Eggs"
                }]
            }
          }
    );

    const { register, formState: { errors }, handleSubmit } = methods;

    const [sales, setSales] = useStateWithLocalStorage(
        'sales'
    );
    
    const onSubmit: SubmitHandler<Inputs> = data => {
        if (sales.length > 0) { 
            setSales(JSON.stringify([...JSON.parse(sales), data]))
        } else {
            setSales(JSON.stringify([data]));
        }
        history.push('/sell/history');
        console.log('Added')
    } 

    return (
        <div className="container">
            <h1>Log a sale<span> <Link to="/sell/history">Sales history</Link></span></h1>

            <FormProvider {...methods} >
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2>Sale</h2>
                <p>Enter the details of the transaction below.</p>
                    
                <label className="required">Date</label>
                <p>Enter the date the sale was made.</p>
                {errors.date && <span className="form_error">The sale date is required</span>}
                <input type="date" defaultValue={today} {...register("date", { required: true })} />
                
                <label className="required">Customer</label>
                <p>Enter the details of the customer</p>
                {errors.customer && <span className="form_error">The customer information is required</span>}
                <input type="text" defaultValue="" {...register("customer", { required: true })} />
               

                <label>PO number</label>
                <p>Enter the PO number for the sale if you have one</p>
                <input type="text" defaultValue="" {...register("po_number", { required: false })} />

                <label className="required">Invoice number</label>
                <p>Enter the invoice number for the sale</p>
                {errors.invoice_number && <span className="form_error">The invoice number is required</span>}
                <input type="text" defaultValue="" {...register("invoice_number", { required: true })} />
                
                    
                <label>Invoice value</label>
                <p>Enter the value of the sale</p>
                {errors.invoice_value && <span className="form_error">The invoice value is required</span>}
                $ <input type="number" defaultValue="" {...register("invoice_value")} /> <span className="endnote">(inc GST)</span>
                    
                <div className="SaleContent">
                    <SellContentRow />        
                </div>

                <h3>Additional Information</h3>
                <label>Notes</label>
                <textarea { ...register("comment") } placeholder="Enter any additional comments or notes here." /> 
                 <SubmitFormButtons />
            </form>
            </FormProvider>
        </div>
    );
};

export default Sell;