import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="container">
            <h1>Oh no!</h1>
            <div className="not-found">
                <p>There is nothing here. Please go back to the <Link to="/">start</Link> page.</p>
            </div>
        </div>
    );
};

export default NotFound;