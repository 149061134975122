import { Link } from 'react-router-dom';

const Navigation = () => {
    return (
        <section id="navigation">
            <div className="links">
                <Link to="/">Start</Link>
                <Link to="/collection">Collection</Link>
                <Link to="/grade">Grading</Link>
                <Link to="/transfer">Transfer</Link>
                <Link to="/sell">Sales</Link>
                <Link to="/reports">Reports</Link>
            </div>
        </section>
    );
};

export default Navigation;







