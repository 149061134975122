interface transferProps {
    transfers: Array<any>;
}

const TransferHistoryMeta = ({ transfers }: transferProps) => {
    return (
        <p className="grademeta"><strong>Transfers completed to date:</strong>  { transfers.length }</p>
    );
};

export default TransferHistoryMeta;