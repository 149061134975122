import { Link } from 'react-router-dom';
import GradeHistoryMeta from './GradeHistoryMeta';
import { calculateEggCount } from '../utils/calculateEggCount';
import { useState } from 'react';
import React from 'react';
import PrintButton from './PrintButton';
import { formatDate } from '../utils/formatDate';
import {Bar} from 'react-chartjs-2';
//import CSVExportButton from './CSVExportButton';
import { useForm } from "react-hook-form";

type GradeHistoryForm = {
    period_start_date: string;
    period_end_date: string;
}

type EggsRecieved = {
    shed: string;
    type: string;
    date: string;
    supplier: string;
    qty: number;
    eggs_unit: string;
}

type EggsGraded = {
    brand: string;
    grade: string;
    qty: number;
    eggs_unit: string;
}
  
type GradeSession = {
    start_date: string;
    start_time: string;
    end_date: string;
    end_time: string;
    eggs_recieved: EggsRecieved[];
    eggs_graded: EggsGraded[];
    graded_total: number;
    waste_count: number;
    waste_unit: string;
    comment: string;
};

const GradeHistory = () => {

    const { register, watch } = useForm<GradeHistoryForm>();

    let total: number = 0;
    function sum(values: EggsGraded[] | EggsRecieved[]) {
        total = 0;
        if (typeof values !== 'undefined') {

            values.forEach((row: EggsGraded | EggsRecieved) => {
                total += Number(row.qty);
            });

        
            if (isNaN(total)) {
                total = 0;
            }
        }
        return total;
    }

    function advice() {
        if ((sum(gradingsArray[parseInt(selected)].eggs_recieved) - sum(gradingsArray[parseInt(selected)].eggs_graded)) - calculateEggCount(gradingsArray[parseInt(selected)].waste_count, gradingsArray[parseInt(selected)].waste_unit) > 0) {
            return (<p className="noMatch">❌&nbsp;&nbsp;The balance of the eggs recieved and the eggs graded did not match. This means that during this grading session you graded or recorded fewer eggs than you accounted for recieving.</p>)
        } else if ((sum(gradingsArray[parseInt(selected)].eggs_recieved) - sum(gradingsArray[parseInt(selected)].eggs_graded)) - calculateEggCount(gradingsArray[parseInt(selected)].waste_count, gradingsArray[parseInt(selected)].waste_unit) < 0) {
            return (<p className="noMatch">❌&nbsp;&nbsp;The balance of the eggs recieved and the eggs graded did not match. This means that during this grading session you graded or recorded as waste more eggs than you accounted for recieving.</p>)
        } else {
            return (<p className="noMatch">✅&nbsp;&nbsp;The balance of this grading session is 0. This means that all eggs have been accounted for.</p>)
        }

    }

    const gradings = localStorage.getItem("gradings") || '{}';
    
    // eslint-disable-next-line
    const [selected, setSelected] = useState('0');
    
    let gradingsArray: GradeSession[] = [];
    let comment: string = 'n/a';

    try {
        gradingsArray = JSON.parse(gradings);
        //gradingsArray.reverse()
        if (gradingsArray[parseInt(selected)].comment !== '') {
            comment = gradingsArray[parseInt(selected)].comment
        }
    } catch (e) {
        gradingsArray = [];
    }

    let watchPeriodStartDate: any = null
    let watchPeriodEndDate: any = null

    if (gradingsArray.length !== 0) {
        watchPeriodStartDate = watch("period_start_date", gradingsArray.reverse()[0].start_date);
        watchPeriodEndDate = watch("period_end_date", gradingsArray[gradingsArray.length - 1].start_date);
    }

    let gradingsInDay = gradingsArray.filter(obj => { 
        return new Date(obj.start_date) >= new Date(watchPeriodStartDate) && new Date(obj.start_date) <= new Date(watchPeriodEndDate)
    });

    let filteredGradingsArray: GradeSession[] = gradingsArray.filter((obj, index, self) =>
        new Date(obj.start_date) >= new Date(watchPeriodStartDate)
    );

    if (watchPeriodStartDate && watchPeriodEndDate) {
        let filterEndDate = ""
        if (new Date(watchPeriodStartDate) > new Date(watchPeriodEndDate)) {
            filterEndDate = watchPeriodStartDate
        } else {
            filterEndDate = watchPeriodEndDate
        }
        filteredGradingsArray = filteredGradingsArray.filter((obj, index, self) => {
            return new Date(obj.start_date) >= new Date(watchPeriodStartDate) && new Date(obj.start_date) <= new Date(filterEndDate)
        });
    }

   
    //TODO: Decide if this is to be kept
    var total_incoming = new Map();
    gradingsArray.forEach((element) => {
        if (total_incoming.get(element.start_date)) total_incoming.set(element.start_date, total_incoming.get(element.start_date) + Number(element.graded_total));
        else total_incoming.set(element.start_date, Number(element.graded_total));
    });
    //const total_incoming_for_graph = Array.from(total_incoming.values());

    var total_graded = new Map();
    filteredGradingsArray.forEach((element) => {
        if (total_graded.get(element.start_date)) total_graded.set(element.start_date, total_graded.get(element.start_date) + Number(sum(element.eggs_recieved)));
        else total_graded.set(element.start_date, Number(element.graded_total));
    });
    const total_graded_for_graph = Array.from(total_graded.values());

    console.log("ALERT",total_graded_for_graph);

    const plot = {

        labels: filteredGradingsArray.filter((obj, index, self) =>
        index === self.findIndex((t) => (
        t.start_date === obj.start_date 
        ))).map((collection: GradeSession) => {
            return collection.start_date;
        }),
        
        datasets: [
            {
                type: 'bar',
                label: 'Graded Total',
                borderWidth: 0,
                backgroundColor: '#f0f0f0',
                data: total_graded_for_graph.map((qty: number) => {
                    return qty;
                })
            } /*,
            {
                type: 'bar',
                label: 'Incoming Total',
                borderWidth: 0,
                backgroundColor: '#d8d8d8',
                data: total_incoming_for_graph.map((qty: number) => {
                    return qty;
                })
            } */
        ]
    }

    let incoming_total = 0;
    let graded_total = 0;
    let wasted_total = 0;

    gradingsInDay.map((grade: GradeSession) =>
        grade.eggs_recieved.map((recieved: EggsRecieved) =>
            incoming_total += Number(recieved.qty)
        )
    );

    gradingsInDay.map((grade: GradeSession) =>
        grade.eggs_graded.map((recieved: EggsGraded) =>
            graded_total += Number(recieved.qty)
        )
    );

    gradingsInDay.map((grade: GradeSession) =>
        wasted_total += calculateEggCount(grade.waste_count, grade.waste_unit)
    );

    let endDateArray = gradingsArray.filter((obj, index, self) =>
    index === self.findIndex((t) => (
    t.start_date === obj.start_date 
    ))).filter(grade => { 
        return new Date(grade.start_date) >= new Date(watchPeriodStartDate)
    });

    if (gradingsArray.length > 0) {
        return (
            <div className="container">
                <h1>Grading history<span> <Link to="/grade">Grade</Link></span></h1>
    
                <GradeHistoryMeta grades={gradingsArray} />
                <form>
                    <h2>Sessions</h2>
                    <div className="dateRangeContainer">
                        <label>Session Dates:</label>
                        <p>Choose the start date and the end date of the period you wish to view.</p>
                        <select className="resetLength" {...register("period_start_date", { required: true })}>
                            {
                                gradingsArray.filter((obj, index, self) =>
                                index === self.findIndex((t) => (
                                t.start_date === obj.start_date 
                                ))).map((grade: GradeSession, index) => {
                                   return <option key={index} value={grade.start_date}>{formatDate(grade.start_date)}</option>
                                })
                            }
                        </select>

                        &nbsp;to&nbsp;

                        <select className="resetLength" {...register("period_end_date", { required: true })}>
                            {console.log('END DATE ARRAY', endDateArray)}
                            {
                                endDateArray.filter((obj, index, self) =>
                                index === self.findIndex((t) => (
                                t.start_date === obj.start_date 
                                ))).map((grade: GradeSession, index) => {
                                        return <option key={index} value={grade.start_date}>{formatDate(grade.start_date)}</option>
                                })
                            }
                            </select>
                    </div>
                
                    <div className="gradeHistoryEntry">
                    <h3>Balance</h3>
                        <Bar
                            data={plot}
                            options={{
                                title: {
                                    display: true,
                                    text: 'Daily egg count from collections',
                                    fontSize: 20
                                },
                                legend: {
                                    display: true,
                                    position: 'right'
                                },
                                scales: {
                                    x: {
                                        stacked: true
                                    },
                                    y: {
                                        stacked: true
                                    }
                                }
                            }}
                        />

                        <h3>Incoming</h3>
                        <table>
                        <thead>
                        <tr>
                            <th>Laying Date</th>
                            <th>Shed</th>
                            <th>Type</th>
                            <th>Supplier</th>
                            <th>Quantity</th>  
                                </tr>
                            </thead>
                        <tbody>
                        {filteredGradingsArray.map((grade: GradeSession, index: number) =>
                            grade.eggs_recieved.map((recieved: EggsRecieved, index: number) => 
                            <React.Fragment key={index}>       
                            <tr>
                                <td>{ recieved.date }</td>
                                <td>{ recieved.shed }</td>
                                <td>{ recieved.type }</td>
                                <td>{ recieved.supplier }</td>
                                <td>{ recieved.qty }</td>
                                </tr>
                                </React.Fragment> 
                            )
                            )
                        }
                        <tr className="totals">
                            <td>Total</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{incoming_total}</td>
                            </tr>
                            </tbody>
                        </table>


                        <h3>Graded</h3>
                        <table>
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Brand</th>
                            <th>Grade/Size</th>
                            <th>Quantity</th>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredGradingsArray.map((grade: GradeSession, index: number) =>
                                grade.eggs_graded.map((graded: EggsGraded, index: number) => 
                                <React.Fragment key={index}>       
                                <tr>
                                    <td>{ grade.start_date }</td>
                                    <td>{ graded.brand }</td>
                                    <td>{ graded.grade }</td>
                                    <td>{ graded.qty }</td>
                                    </tr>
                                    </React.Fragment> 
                                    )
                                )
                            }    
                        
                        <tr className="totals">
                            <td>Graded</td>
                            <td></td>
                            <td></td>
                            <td>{graded_total}</td>        
                            </tr>
                        <tr className="totals">
                            <td>Waste</td>
                            <td></td>
                            <td></td>
                            <td>{wasted_total}</td>        
                            </tr>
                        <tr className="totals">
                            <td>Total</td>
                            <td></td>
                            <td></td>
                            <td>{graded_total+wasted_total}</td>        
                            </tr>
                        <tr className="totals nobottom">
                            <td colSpan={2}>Balance <span>(incoming-(graded+waste))</span><br />{advice()}</td>
                            <td className="white"></td>
                            <td className="white" valign="top">{sum(gradingsArray[parseInt(selected)].eggs_recieved) - sum(gradingsArray[parseInt(selected)].eggs_graded) - calculateEggCount(gradingsArray[parseInt(selected)].waste_count, gradingsArray[parseInt(selected)].waste_unit)}</td>        
                            </tr>
                            </tbody>
                        </table>

                        <div className="notes"><h3>Notes</h3>
                        {comment}
                        </div>

                        <PrintButton />
                        {/*<CSVExportButton />*/}
                    </div>
                </form>
            </div>
        )
    }
    else {
        return (
            <div className="container">
                <h1>Grade history<span> <Link to="/grade">Grade</Link></span></h1>
        
                <GradeHistoryMeta grades={gradingsArray} />
                <form>
                    <h2>There is no grading history...</h2>
                    <p>You haven't completed any gradings yet. <Link to="/grade">You can enter a grading here.</Link> </p>
                </form>
            </div>
        );
    }
};

export default GradeHistory;


