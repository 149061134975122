
const Help = () => {
    return (
        <div className="container">
        <h1>Help</h1>
        <div className="content help">


                <div className="help-sections">
                <h3>Help sections</h3>
                <ul><li><a href="#collection">Egg collection</a></li>
                    <li><a href="#grade">Grade some eggs</a></li>
                    <li><a href="#transfer">Transfer some eggs on/off the farm</a></li>
                    <li><a href="#sell">Sell some eggs</a></li>
                    <li><a href="#reports">Reports</a></li>
                    </ul>
                </div>
            

            <h3><span id="collection" />Egg collection</h3>

            <ul><li>Used to log a collection from a shed.</li>
                <li>Captures when an egg was collected, where and by whom.</li>
                <li>Captures wastage.</li>
                <li>Captures other environmental information along with feed and water use.</li>
                <li>Calculates total egg number from eggs collected along with floor count.</li>
                <li>You need to enter a location for where eggs are stored. This might be in the shed or elsewhere - for example in the cold store.</li>
                <li>The notes section can be used to add any additional information that may be of use.</li>
                </ul>

            <h3>Collection History</h3>

            <ul><li>The collection history page shows the history of all collections. This can be viewed through a number of graphs along with detailed information in tabular form.</li>
                <li>The user can choose the period they want to see information for.</li>
                    <li>The graphs show historic information for
                        <ul><li>Shed performance (number of eggs)</li>
                            <li>Water intake</li>
                            <li>Food intake</li>
                            <li>Shed temperature</li>
                            <li>Deceased birds</li>
                            </ul></li>
                <li>The data selected can also be printed or downloaded as a csv for use in excel etc.</li>
                </ul>

            <h3><span id="grade" />Grade some eggs</h3>

            <ul><li>This allows for the capture of the grading sessions.</li>
                <li>Allows for the capture of the Julian date.</li>
                <li>The date and time of the grading session.</li>
                <li>The person responsible for the grading session.</li>
                <li>The eggs that have been collected are brought into the grading session.</li>
                <li>Users can choose how many of these eggs will be graded. The remainder is retained and will be kept in the tool for grading later.</li>
                <li>Rows can be removed or the number of eggs adjusted.</li>
                <li>Eggs packed represents the final graded eggs. The brand, quantity and grade of the eggs can be captured. </li>
                <li>New rows can be added to represent different product/grade types.</li>
                <li>The incoming eggs and the graded eggs quantities MUST be equal with the balance equalling 0.</li>
                <li>If the incoming/packed eggs balance is not 0 the session cannot be completed.</li>
                <li>As with the other pages there is a notes section to capture additional information.</li>
                </ul>

            <h3>Grading History</h3>

            <ul><li>The grading history page shows the number of eggs graded and each of the grading sessions that have been performed.</li>
                <li>The grading session provides information about each grade covering volume, brand and quantity.</li>
                <li>As with the other history pages, the data can be printed or downloaded as CSV.</li>
                        </ul>


            <h3><span id="transfer" />Transfer</h3>

            <ul><li>This page allows the user to record the transfer of eggs around and between properties.</li>
                <li>It is not required to use the tool but enables a greater level of traceability.</li>
                <li>The user can record:
                    <ul><li>The transfer date</li>
                        <li>The person responsible for the transfer</li>
                        <li>The type (on farm, between farms etc)</li>
                        <li>Source</li>
                        <li>Destination</li>
                        <li>And if required the pallet ID</li>
                        <li>The user can also add rows of information about the product being transferred</li>
                        </ul></li>
                <li>Again, additional notes can be captured if need to capture additional information about the transfer.</li>
                </ul>

                <h3>Transfer history</h3>
                
                <ul><li>The transfer history page allows the user to review previous transfers by day.</li></ul>

            <h3><span id="sales" />Sales</h3>
                
            <ul><li>Sales can be captured using this page if needed.</li>
                <li>If needed this is available for use to enable full traceability from shed through to sale.</li>
                <li>The user can capture:
                    <ul><li>The date of the sale</li>
                        <li>The customer</li>
                        <li>The PO number</li>
                        <li>Invoice number</li>
                        <li>Invoice value </li>
                        <li>Line items of the sale - these are added row by row using the add button</li>
                        <li>As with the other pages additional information can be captured.</li>
                        </ul>
                        </li></ul>

            <h3>Sales History</h3>
                        
                <ul><li>The sales history page allows the user to review previous sales by day.</li>
                            </ul>


            <h3><span id="reports" />Reports</h3>
                        
            <ul><li>Currently only one report - the trace report.</li>
                <li>The user enters in the best before date of the product that they want to trace and the report returns all the product that was graded that day and links it to the shed from which it was collected.</li>
                </ul>


            </div>
            </div>
    );
};

export default Help;