import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Footer from './components/Footer';
import Start from './components/Start';
import Navigation from './components/Navigation';
import Collection from './components/Collection';
import CollectionHistory from './components/CollectionHistory';
import Grade from './components/Grade';
import GradeHistory from './components/GradeHistory';
import Transfer from './components/Transfer';
import TransferHistory from './components/TransferHistory';
import Sell from './components/Sell';
import SellHistory from './components/SellHistory';
import Reports from './components/Reports';
import NotFound from './components/NotFound';
import RecallReport from './components/RecallReport';
import Help from './components/Help';
import About from './components/About';

function App() {

  //const reload = () => window.location.reload();

  return (
    <>
      <Router>
      <Navigation />
        <Switch>
          <Route path="/" component={Start} exact />
          <Route path="/collection" component={Collection} exact />
          <Route path="/collection/history" component={CollectionHistory} exact />
          <Route path="/grade" component={Grade} exact />
          <Route path="/grading/history" component={GradeHistory} exact />
          <Route path="/transfer" component={Transfer} exact />
          <Route path="/transfer/history" component={TransferHistory} exact />
          <Route path="/sell" component={Sell} exact />
          <Route path="/sell/history" component={SellHistory} exact />
          <Route path="/reports" component={Reports} exact />
          <Route path="/reports/recall" component={RecallReport} exact />
          <Route path="/help" component={Help} exact />
          <Route path="/about" component={About} exact />
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </Router>
      </>
  );
}

export default App;
