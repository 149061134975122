import { Link, useHistory } from 'react-router-dom';
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import TransferContentRow from './TransferContentRow';
import SubmitFormButtons from './SubmitFormButtons';
import { useStateWithLocalStorage } from '../utils/useStateWithLocalStorage';

const Transfer = () => {

    type Inputs = {
        date: string;
        shed: string;
        flock: string;
        count: number;
        deceased_count: number;
        floor_count: number;
        operator: string;
        pallet_id: string;
        transfer_from: string;
        transfer_to: string;
        comment: string;
    };
    
    const history = useHistory();
    
    const time_now = new Date();
    var day = ("0" + time_now.getDate()).slice(-2);
    var month = ("0" + (time_now.getMonth() + 1)).slice(-2);
    var today = time_now.getFullYear() + "-" + (month) + "-" + (day)
    
    const { ...methods } = useForm(
        {
            defaultValues: {
                date: today,
                operator: "n/a",
                transfer_from: "n/a",
                transfer_to: "n/a",
                pallet_id: "n/a",
                transfer_type: "On Farm",
                comment: "",
                transfers: [{
                    shed: "Shed",
                    type: "AAA",
                    esa_compliant: "Yes",
                    qty: 0,
                    unit: "Eggs"
                }]
            }
          }
    );

    const { register, formState: { errors }, handleSubmit } = methods;
    
    const [transfers, setTransfers] = useStateWithLocalStorage(
        'transfers'
    ); 

    const onSubmit: SubmitHandler<Inputs> = data => {
        if (transfers.length > 0) { 
            setTransfers(JSON.stringify([...JSON.parse(transfers), data]))
        } else {
            setTransfers(JSON.stringify([data]));
        }
        history.push('/transfer/history');
        console.log('Added')
    } 

    const transfer_types = ['On Farm', 'Internal', 'Off Farm']

    return (
        <div className="container">
            <h1>Perform a transfer<span> <Link to="/transfer/history">Transfer history</Link></span></h1>

            <FormProvider {...methods} >
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2>Transfer</h2>
                <p>Enter the details of the transfer below.</p>
                    
                <label className="required">Date</label>
                    <p>Enter the date and time that the transfer was made.</p>
                    {errors.date && <span className="form_error">The transfer date is required</span>}
                <input type="date" defaultValue={today} {...register("date", { required: true })} />
                    
                <label className="required">Operator</label>
                    <p>Who is responsible for the transfer?</p>
                    {errors.operator && <span className="form_error">The transfer operator is required</span>}
                    <input type="text" defaultValue="" {...register("operator", { required: true })} />
                    
                <label className="required">Transfer type</label>
                    <p>What kind of transfer is this. Choose from the options below.</p>
                    {errors.transfer_type && <span className="form_error">The transfer type is required</span>}
                    <select {...register("transfer_type", { required: true })}>
                {transfer_types.map((option, index) =>
                    <option key={index}>{option}</option>
                )}
                </select>
                
                <label className="required">Transfer From</label>
                <p>Enter the transfer point of origin, e.g. Shed, Cold Store, Grading Floor etc.</p>
                    {errors.transfer_from && <span className="form_error">The transfer from location is required</span>}
                <input type="text" defaultValue="" {...register("transfer_from", { required: true })} />
                {errors.date && <span className="form_error">The collection date is required</span>}
                    
                <label className="required">Transfer To</label>
                <p>Enter the transfer destination, e.g. Shed, Cold Store, Grading Floor etc.</p>
                <input type="text" defaultValue="" {...register("transfer_to", { required: true })} />
                {errors.transfer_to && <span className="form_error">The transfer from location is required</span>}

                <label className="required">Pallet Identifier</label>
                <p>Enter the identifier for the pallet if applicable</p>
                <input type="text" defaultValue="" {...register("pallet_id", { required: false })} />
                
                <div className="TransferContent">         
                        <TransferContentRow />
                </div>
                    <h3>Additional Information</h3>
                    <label>Notes</label>
                    <textarea { ...register("comment") } placeholder="Enter any additional comments or notes here." />    
                <SubmitFormButtons />
            </form>
            </FormProvider>
        </div>
    );
};

export default Transfer;