
interface Balance {
    in: number;
    out: number;
    waste: number;
}

const GradingBalance = (props:Balance) => {

    const finalBalance = props.in - (props.out + props.waste)
    let message: string = "";
    let css: string = "";

    if (finalBalance > 0) {
        message = "The incoming and grading balances do not match. You must account for the remaining eggs before you can submit this grading session.";
        css = "gradingBalanceError";
    } else if (finalBalance < 0) {
        message = "The incoming and grading balances do not match. You have graded more eggs than you have collected. You must account for the remaining eggs before you can submit this grading session."
        css = "gradingBalanceError";
    } else if (finalBalance === 0) {
        message = "The grading session is balanced."
        css = "gradingBalance";
    }

    return (
        <div className="item">
            <div className={css}>Balance: {finalBalance} <span className="balancewarning">{ message }</span></div>
        </div>
    );
};

export default GradingBalance;