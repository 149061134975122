import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useHistory } from 'react-router-dom';
import SubmitFormButtons from "./SubmitFormButtons";
import { useStateWithLocalStorage } from "../utils/useStateWithLocalStorage";
import { calculateEggCount } from "../utils/calculateEggCount";
import { hashString } from "../utils/hashString";
import HelpButton from "./HelpButton";

type TransferItem = {
    shed: string;
    type: string;
    esa_compliant: string;
    qty: number;
    unit: string;
}

type Transfer = {
    date: string;
    operator: string;
    transfer_from: string;
    transfer_to: string;
    pallet_id: string;
    transfer_type: string;
    comment: string;
    transfers: TransferItem[];
}

const Collection = () => {
    type Inputs = {
        id: string;
        date: string;
        shed: string;
        type: string;
        flock: String,
        eggs_count: string;
        eggs_unit: string;
        qty: number;
        floor_count: string;
        floor_count_unit: string;
        waste_count: string;
        waste_unit: string;
        deceased_count: number;
        comment: string;
        collected_by: string;
        grade_status: number;
        supplier: string;
        graded: number;
        graded_qty: number;
        storage_location: string;
        flock_age: number;
        water_consumption: number;
        feed_consumption: number;
        shed_temperature: number;
      };
    
    const history = useHistory();
    
    const { register, handleSubmit, watch, formState: { errors } } = useForm<Inputs>();
    
    const [collections, setCollections] = useStateWithLocalStorage(
        'collections'
    );

    const [transfers, setTransfers] = useStateWithLocalStorage(
        'transfers'
    );

    function AddTransfers(transfersMade:Transfer) {
        
        if (transfers.length > 0) { 
            setTransfers(JSON.stringify([...JSON.parse(transfers), transfersMade]))
        } else {
            setTransfers(JSON.stringify([transfersMade]));
        }
    
        return transfers;
    };

    const onSubmit: SubmitHandler<Inputs> = data => {

        data.qty = total;
        //setValue('eggs_count', parseInt(data.eggs_count);
        if (collections.length > 0) {
            setCollections(JSON.stringify([...JSON.parse(collections), data]))
        } else {
            setCollections(JSON.stringify([data]))
        }

        //Add transfer if needed
        if (data.shed !== data.storage_location) {
            const transferObj: Transfer = {
                date: values.date,
                operator: values.collected_by,
                transfer_from: values.shed,
                transfer_to: values.storage_location,
                pallet_id: "N/A",
                transfer_type: "Internal",
                comment: "",
                transfers: [{
                    shed: values.shed,
                    type: "N/A",
                    esa_compliant: "N/A",
                    qty: total,
                    unit: "Eggs"
                }]
            };

            AddTransfers(transferObj); //TODO: Need error check here
        }

        history.push('/collection/history');
    };
    
    const units = ['Eggs', 'Dozen', 'Trays'];
    const production_types = ['Cage', 'Barn', 'Free Range', 'Organic', 'Other'];

    let values = watch();

    const time_now = new Date();
    var day = ("0" + time_now.getDate()).slice(-2);
    var month = ("0" + (time_now.getMonth() + 1)).slice(-2);

    var today = time_now.getFullYear() + "-" + (month) + "-" + (day);
    
    let total: number = 0;

    function sum() {
        if (typeof values !== 'undefined') {
            total = calculateEggCount(parseInt(values.eggs_count), values.eggs_unit) + calculateEggCount(parseInt(values.floor_count), values.floor_count_unit)
            return (total)

        }
        return total;
    }

    return (
        <div className="container">
            <h1>Make a new collection <span><Link to="/collection/history">View collection history</Link></span></h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2>Add a Collection <HelpButton></HelpButton></h2>
                <input type="hidden" value={hashString(Date.now().toString())} {...register("id")} />
                <label className="required">Date</label>
                <p>Enter the date that the collection was made.</p>
                <input type="date" defaultValue={today} {...register("date", { required: true })} />
                {errors.date && <span className="form_error">You must enter a collection date.</span>}

                <label className="required">Collected by</label>
                <p>Who made the collection?</p>
                {errors.shed && <span className="form_error">You must enter a name.</span>}
                <input type="text" {...register("collected_by", { required: true })} />

                <label className="required">Shed</label>
                <p>Enter the shed where the collection was made.</p>
                {errors.shed && <span className="form_error">You must enter a shed.</span>}
                <input type="text" {...register("shed", { required: true })} />

                <label className="required">Production Type</label>
                <p>Enter the production type.</p>
                {errors.shed && <span className="form_error">You must enter a production type.</span>}
                <select {...register("type")}>
                        {production_types.map((option, index) =>
                            <option key={index}>{option}</option>
                        )}
                        </select>

                <label className="required">Eggs collected</label>
                <p>Enter the number of eggs collected. This number should not include any damaged or cracked eggs.</p>
                {errors.eggs_count && <span className="form_error">You must enter the number of eggs collected.</span>}
                <div className="inputWithUnit">
                    <div className="input">
                        <input type="number" min="0" defaultValue="0" {...register("eggs_count", { required: true })} />
                    </div>
                    <div className="unit">
                        <select {...register("eggs_unit")}>
                        {units.map((option, index) =>
                            <option key={index}>{option}</option>
                        )}
                        </select>
                    </div>
                </div>

                <label className="required">Floor count</label>
                {errors.floor_count && <span className="form_error">You must enter the number of eggs in the floor count.</span>}
                <div className="inputWithUnit">
                    <div className="input">
                        <input type="number" min="0" defaultValue="0" {...register("floor_count", { required: true })} />
                    </div>
                    <div className="unit">
                        <select {...register("floor_count_unit")}>
                        {units.map((option, index) =>
                            <option key={index}>{option}</option>
                        )}
                        </select>
                    </div>
                </div>

                <span className="collection_total">Total: {sum()}</span>

                <h3>Additional Information</h3>
                <label className="required">Waste</label>
                <p>Were any cracked or damaged eggs recovered during the collection? If so, enter the number here. These should not be included in the collection count above.</p>
                <div className="inputWithUnit">
                    <div className="input">
                        <input type="number" min="0" defaultValue="0" {...register("waste_count", { required: true })} />
                    </div>
                    <div className="unit">
                        <select {...register("waste_unit")}>
                        {units.map((option, index) =>
                            <option key={index}>{option}</option>
                        )}
                        </select>
                    </div>
                </div>
                <div className="inputWithUnit">{errors.waste_count && <span className="form_error">You must enter the number of eggs wasted.</span>}</div>

                <label>Flock Age (Months)</label>
                <p>The age of the flock in this shed in months.</p>
                <input type="number" min="0" defaultValue="0" {...register("flock_age", { required: true })} />
                {errors.flock_age && <span className="form_error">You must enter the age of the birds in the shed</span>}
                
                <label className="required">Deceased birds</label>
                <p>Did you find any dead birds? If so enter the number here.?</p>
                <input type="number" min="0" defaultValue="0" {...register("deceased_count", { required: true })} />
                {errors.deceased_count && <span className="form_error">You must enter any deceased birds.</span>}

                <label className="required">Water consumption (Litres)</label>
                <p>Enter the amount of water consumed since the last collection.</p>
                <input type="number" min="0" defaultValue="0" {...register("water_consumption", { required: true })} />
                {errors.water_consumption && <span className="form_error">You must enter the water consumption in Litres</span>}

                <label className="required">Feed consumption (KGs)</label>
                <p>Enter the amount of feed consumed since the last collection.</p>
                <input type="number" min="0" defaultValue="0" {...register("feed_consumption", { required: true })} />
                {errors.feed_consumption && <span className="form_error">You must enter the water consumption in Litres</span>}

                <label className="required">Shed Temperature (ºC)</label>
                <p>What was the average temperature of the shed since the last collection?</p>
                <input type="number" min="0" defaultValue="0" {...register("shed_temperature", { required: true })} />
                {errors.shed_temperature && <span className="form_error">You must enter the shed temperature in degrees celcius</span>}
                
                <label className="required">Storage Location</label>
                <p>Enter the location where the eggs are stored after collection, this might be within the shed or you may take them to a cold store.</p>
                {errors.storage_location && <span className="form_error">You must enter a storage location</span>}
                <input type="text" {...register("storage_location", { required: true })} />
                <p className="endnote">If the location you enter does not match the name shed where the eggs were collected then a transfer record recording the movement of the eggs to the new location will be logged on your behalf.</p>


                <label>Notes</label>
                <textarea {...register("comment")} placeholder="Enter any additional comments or notes here." />
                <input type="hidden" value="0" {...register("grade_status")} />
                <input type="hidden" value="n/a" {...register("supplier")} />
                <input type="hidden" value="" {...register("qty")} />
                <input type="hidden" value="0" {...register("graded_qty")} />
                <SubmitFormButtons />
            </form>
        </div>
    );
};

export default Collection;