import { Link } from 'react-router-dom';
import { convertDate } from '../utils/formatDate';

interface statProps {
    title: string;
    value: string;
    since_date: string;
    link: string;
    history_link: string;
    link_text: string;
    size: string;
}

const StatBox = ({ title, link, history_link, since_date, value, link_text, size }: statProps) => {

    const blocksize = "start_stat_container "+size;

    return (
        <div className={blocksize}>
            <h3>{ title }</h3>
            <p className="start_stat"><Link to={ history_link} >{ value }</Link><span>{ since_date !== '' ? "Since: "+convertDate(since_date) : 'There are no records yet' }</span></p>
            <p><Link to={ link }>{ link_text } &raquo;</Link></p>
        </div>
    );
};

export default StatBox;







