interface gradeProps {
    grades: Array<any>;
}

const GradeHistoryMeta = ({ grades }: gradeProps) => {
    return (
        <p className="grademeta"><strong>Gradings completed to date:</strong>  { grades.length }</p>
    );
};

export default GradeHistoryMeta;