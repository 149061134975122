interface collectionProps {
    collections: Array<any>;
}

const CollectionHistoryMeta = ({ collections }: collectionProps) => {
    return (
        <p className="collectionmeta"><strong>Collections made to date:</strong>  { collections.length }</p>
    );
};

export default CollectionHistoryMeta;