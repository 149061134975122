import { Link } from "react-router-dom";

const Footer= () => {
    return (
        <section className="footer">
            <p><Link to={process.env.PUBLIC_URL + '/eula.pdf'} target="blank">Terms and Conditions</Link></p>
            <p>&copy; 2002 - 2022 Australian Eggs Limited. All Rights Reserved.</p>
        </section>
    );
};

export default Footer;