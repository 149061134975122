import { Link } from 'react-router-dom';

interface card_props {
    title: string,
    description: string,
    link: string
}

const ReportCard = ({title, description, link}:card_props) => {
    return (
        <div className="reportcard">
            <h4><Link to={link}>{title}</Link></h4>
            <p>{description}</p>
        </div>
    );
};

export default ReportCard;