import { useFormContext, useFieldArray } from "react-hook-form";
import { calculateEggCount } from "../utils/calculateEggCount";
import UtilityButton from "./UtilityButton";

interface saleEntry {
    brand: string;
    type: string;
    qty: number;
    unit: string;
}

const SellContentRow = () => {

    const { register, control, watch, formState: { errors } } = useFormContext()

    const { fields, append, remove } = useFieldArray({
        control,
        name: "products"
    });

    let values = watch();

    const unit = ['Eggs', 'Dozen', 'Trays']
    
    let total: number = 0;
    
    function sum(values: saleEntry[]) {
        if (typeof values !== 'undefined') {
            values.forEach((row: saleEntry) => {
                if (typeof row.qty !== 'undefined' && typeof row.unit !== 'undefined') { 
                    total += calculateEggCount(row.qty, row.unit.toLowerCase())
                }
            });

        
            if (isNaN(total)) {
                total = 0;
            }
        }
        
        return total;
    }

    const addRowClick = () => {
        if (fields.length < 5) {
            append({ brand:"n/a", type:"n/a", qty:0, unit:"Eggs"});
        }
    };

    return (
        <>
            <div className="items">
                <div className="shed">
                    <label className="required">Brand</label>
                </div>

                <div className="type">
                    <label className="required">Type/Size</label>
                </div>

                <div className="number"> 
                    <label className="required">Quantity</label>
                </div>
                    
                <div className="number"> 
                    <label className="required">Unit</label>
                </div>
                <div></div>
            </div>

            { fields.map((data, index) => {
                return (
                    <div className="items" key={ index }>
                        <div className="brand">
                            <input type="text" {...register(`products.${index}.brand` as const, { required: true })} className={ errors?.['products']?.[index]?.['brand'] ? "highlight" : '' } />
                        </div>

                        <div className="type">
                            <input type="text" {...register(`products.${index}.type` as const, { required: true })} className={ errors?.['products']?.[index]?.['type'] ? "highlight" : '' } />
                        </div>

                        <div className="qty">
                            <input type="number" min="0" defaultValue="0" {...register(`products.${index}.qty` as const, { required: true })} className={ errors?.['products']?.[index]?.['qty'] ? "highlight" : '' } />
                        </div>

                        <div className="unit">
                            <select {...register(`products.${index}.unit` as const, { required: true })} className={ errors?.['products']?.[index]?.['unit'] ? "highlight" : '' } >
                            {unit.map((option, index) =>
                                <option value={ option } key={index}>{option}</option>
                            )}
                            </select>   
                        </div>
                        {index>0 && 
                        <div><button className="close" type="button" onClick={() => index > 0 ? remove(index) : {}}>x</button></div>
                        }
                    </div>
                );
            }) }
            
            <div className="transferTools">
                <div className="addRowControl">
                    <UtilityButton name="Add row" onClick={addRowClick} />
                </div>
                <div className="flex"></div>
                <div className="totals">
                    <h4>Total:</h4>
                    <span className="GradeTotal">{ sum(values.products) }</span>
                </div>
            </div> 

        </>
    );
};

export default SellContentRow;