const SubmitFormButtons = () => {
    return (
        <div className="submitFormButtons">
            <div className="flex" />
            <div className="cancel">
                <button type="reset">Cancel</button>
                </div>
            <div className="submit">
                <button type="submit">Save</button>
            </div>
        </div>
    );
};

export default SubmitFormButtons;