import { Link, useHistory } from 'react-router-dom';
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import GradingInputRow from './GradingInputRow';
import GradingOutputRow from './GradingOutputRow';
import SubmitFormButtons from './SubmitFormButtons';
import { useStateWithLocalStorage } from "../utils/useStateWithLocalStorage";
import GradingWastage from "./GradingWastage";
import GradingBalance from './GradingBalance';
import { calculateEggCount } from '../utils/calculateEggCount';
import { hashString } from '../utils/hashString';

const Grade = () => {

    type Collection = {
        shed: string;
        type: string;
        laying_date: string;
        qty: number;
        supplier: string;
        eggs_count: number;
        eggs_unit: string;
        grade_status: number;
        graded_qty: number
        waste_count: number;
        waste_unit: string;
        id: number;
        transferUngradedEggs: string;
        graded_by: string;
        julian_date: number;
        graded_total: number;
    };
    
    type TransferItem = {
        shed: string;
        type: string;
        esa_compliant: string;
        qty: number;
        unit: string;
    }

    type Transfer = {
        date: string;
        operator: string;
        transfer_from: string;
        transfer_to: string;
        pallet_id: string;
        transfer_type: string;
        comment: string;
        transfers: TransferItem[];
    }

    //const { register, watch, handleSubmit, formState: { errors } } = useForm();

    const history = useHistory();

    const time_now = new Date();
    var day = ("0" + time_now.getDate()).slice(-2);
    var month = ("0" + (time_now.getMonth() + 1)).slice(-2);
    var today = time_now.getFullYear() + "-" + (month) + "-" + (day)

    var start = new Date(time_now.getFullYear(), 0, 0);
    var diff = (time_now.valueOf() - start.valueOf()) + ((start.getTimezoneOffset() - time_now.getTimezoneOffset()) * 60 * 1000);
    var oneDay = 1000 * 60 * 60 * 24;
    var julian_date = Math.floor(diff / oneDay);

    const [collections, setCollections] = useStateWithLocalStorage(
        'collections'
    );
    
    let fullCollectionsArray: Collection[] = [];
    let collectionsArray: Collection[] = [];
    
    try {
        fullCollectionsArray = JSON.parse(collections);

        collectionsArray = fullCollectionsArray.map(a => ( {...a})); //deep copy

        collectionsArray = collectionsArray.filter(obj => {
            return typeof(obj.grade_status) === "undefined" || Number(obj.grade_status) === 0 || Number(obj.grade_status) === 2;
        });
        console.log('collections', collectionsArray)

        //We update the collections array here to set the unit to eggs. This is needed for the calculations on the grading page.
        collectionsArray.forEach(obj => {
            if (obj.qty > 0 && (Number(obj.grade_status) === 2 ||  typeof(obj.grade_status) === "undefined")) {
                obj.qty = (obj.qty - obj.graded_qty); 
                obj.eggs_unit = "Eggs";
            } 
        });

    } catch (e) {
        //collectionsArray = [{ "shed": "shed", "type": "type", "laying_date": today, "supplier": "supplier", "qty": 0, "eggs_unit": "eggs", "eggs_count":0, "grade_status":0, "graded_qty":0, "id":0, waste_count:0, waste_unit:"Eggs", transferUngradedEggs:"Cold Store", graded_by:"n/a", julian_date:julian_date, graded_total:0}];
    }

    const { ...methods } = useForm(
        {
            defaultValues: {
                id: hashString(Date.now().toString()),
                start_date: new Date().toLocaleDateString('en-CA'),
                end_date: new Date().toLocaleDateString('en-CA'),
                start_time: (new Date()).toTimeString().split(' ')[0],
                end_time: (new Date()).toTimeString().split(' ')[0],
                graded_by: "n/a",
                comment: "",
                eggs_recieved: collectionsArray,
                eggs_graded: [{ "brand": "n/a", "grade": "AAA", "qty": 0, "eggs_unit": "Eggs" }],
                waste_count: 0,
                waste_unit: "eggs",
                transferUngradedEggs: "Cold store",
                julian_date: julian_date,
                graded_total: 0
            }
          }
    );
    const { register, formState: { errors }, watch, handleSubmit, setValue, getValues } = methods;

    let values = watch();

    const [gradings, setGradings] = useStateWithLocalStorage(
        'gradings'
    );

    const [transfers, setTransfers] = useStateWithLocalStorage(
        'transfers'
    );

    function AddTransfers(transfersMade:Transfer) {
        
        if (transfers.length > 0) { 
            setTransfers(JSON.stringify([...JSON.parse(transfers), transfersMade]))
        } else {
            setTransfers(JSON.stringify([transfersMade]));
        }
    
        return transfers;
    };

    const onSubmit: SubmitHandler<Collection> = data => {
        // Calculate balance here
        //console.log('balance', values.eggs_recieved.reduce((total, entry) => total + entry.qty, 0) - (values.eggs_graded.reduce((total, entry) => total + calculateEggCount(Number(entry.qty), entry.eggs_unit.toLowerCase()), 0) + calculateEggCount(Number(data.waste_count), data.waste_unit.toLowerCase())))
        if (values.eggs_recieved.reduce((total, entry) => total + entry.qty, 0) - (values.eggs_graded.reduce((total, entry) => total + calculateEggCount(Number(entry.qty), entry.eggs_unit.toLowerCase()), 0) + calculateEggCount(Number(data.waste_count), data.waste_unit.toLowerCase())) === 0) {
            
            setValue('graded_total', Number(values.eggs_graded.reduce((total, entry) => total + calculateEggCount(Number(entry.qty), entry.eggs_unit.toLowerCase()), 0)))
            data.graded_total = getValues("graded_total") // TODO remove this brute force.
            //console.log("DATA", data);


            if (gradings.length > 0) { 
                setGradings(JSON.stringify([...JSON.parse(gradings), data]))
            } else {
                setGradings(JSON.stringify([data]));
            }

            //Update collections to flag as graded
            //Split collections??
            fullCollectionsArray.forEach((fobj) => {
                values.eggs_recieved.forEach((robj) => {
                    if (fobj.id === robj.id) {
                        const gradedTotal = Number(fobj.graded_qty) + Number(robj.qty); // + calculateEggCount(Number(robj.waste_count), robj.waste_unit);
                        //console.log("gradedTotal=", gradedTotal)
                        fobj.graded_qty = gradedTotal;
                        if (fobj.qty > fobj.graded_qty) {
                            fobj.grade_status = 2; //Part graded
                        } else if (fobj.qty === fobj.graded_qty) {
                            fobj.grade_status = 1; //Graded15
                        } else {
                            fobj.grade_status = 1; 
                        }
                    }
                });
            });
            
            setCollections(JSON.stringify(fullCollectionsArray));

            //Add transfer if needed
            if (data.transferUngradedEggs !== "") {

                let transferArray: TransferItem[] = [];
                values.eggs_recieved.forEach((obj) => {
                    transferArray = [...transferArray, {
                        shed: obj.shed,
                        type: "N/A",
                        esa_compliant: "N/A",
                        qty: obj.qty - obj.graded_qty,
                        unit: "Eggs"
                    }]
                });

                const transferObj: Transfer = {
                    date: today,
                    operator: values.graded_by,
                    transfer_from: "Grading",
                    transfer_to: values.transferUngradedEggs,
                    pallet_id: "N/A",
                    transfer_type: "Internal",
                    comment: "",
                    transfers: transferArray
                };

                AddTransfers(transferObj); //TODO: Need error check here
            }

            history.push('/grading/history');  
        } else {
            console.log('No match')
        }
    } 

    //let balance:number = values.eggs_recieved.reduce((total, entry) => total + calculateEggCount(Number(entry.qty), entry.unit.toLowerCase()), 0) + calculateEggCount(Number(values.waste_count), values.waste_unit) - values.eggs_graded.reduce((total, entry) => total + calculateEggCount(Number(entry.qty), entry.unit.toLowerCase()), 0) 
    //console.log("col arr", collectionsArray)
    if (collectionsArray.length > 0) {
        return (
            <div className="container">
                <h1>Grade<span> <Link to="/grading/history">Grading history</Link></span></h1>
                <FormProvider {...methods} >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h2>Grading Session <span className="help"><button>Get Help</button></span></h2>
                        <div>
                                <label className="required">Julian Date</label>
                                <p>The Julian packing date of the eggs</p>
                                {errors.julian_date && <span className="form_error">You must enter a name.</span>}
                                <input type="text" {...register("julian_date", { required: true })} />
                        </div>
                        <div className="gradingDates">
                            <div className="item">
                                <label className="required">Grading Date/Time</label>
                                <p>Enter the date and time grading started.</p>
                                {errors.start_date && <span className="form_error">The time and date the grading started is required</span>}
                                <input type="date" defaultValue={today}  {...register("start_date", { required: true })} />
                                <input type="time" step="any" {...register("start_time", { required: true })} />
                            </div>
                        </div>
                        <div className="item graded_by">
                                <label className="required">Graded by</label>
                                <p>Who was responsible for the grading?</p>
                                {errors.graded_by && <span className="form_error">You must enter a name.</span>}
                                <input type="text" {...register("graded_by", { required: true })} />
                        </div>
                        <GradingInputRow />
                        <GradingOutputRow />
                        <h3>Additional Information</h3>
                        <GradingWastage max={ values.eggs_recieved.reduce((total, entry) => total + Number(entry.qty), 0) - values.eggs_graded.reduce((total, entry) => total + calculateEggCount(Number(entry.qty), entry.eggs_unit.toLowerCase()), 0) }/>
                        {collectionsArray.reduce((total, entry) => total + Number(entry.qty), 0) - values.eggs_recieved.reduce((total, entry) => total + Number(entry.qty), 0) > 0 && 
                            <div className="item">
                                <label>Transfer Ungraded Eggs</label>
                                <p>Would you like to add a transfer for the remaining { collectionsArray.reduce((total, entry) => total + Number(entry.qty), 0) - values.eggs_recieved.reduce((total, entry) => total + Number(entry.qty), 0)  } ungraded eggs?</p>
                                <input type="text" {...register('transferUngradedEggs')} />
                            </div>
                        }
                        <label>Notes</label>
                        <textarea {...register("comment")} placeholder="Enter any additional comments or notes here." />
                        <GradingBalance in={values.eggs_recieved.reduce((total, entry) => total + Number(entry.qty), 0)} out={values.eggs_graded.reduce((total, entry) => total + calculateEggCount(Number(entry.qty), entry.eggs_unit.toLowerCase()), 0)} waste={calculateEggCount(Number(values.waste_count), values.waste_unit)} />
                        <SubmitFormButtons />
                        <input type="string" hidden {...register('graded_total') } />
                    </form>
                </FormProvider>
            </div>
        );
    } else {
        return (
            <div className="container">
                <h1>Grade<span> <Link to="/grading/history">Grading history</Link></span></h1>
                    <form>
                        <h2>No collections</h2>
                        <p>You do not have any egg collections to be graded. You can enter collections to be graded <Link to="/collection">here</Link></p>
                    </form>
            </div>
        );
    }
};

export default Grade;