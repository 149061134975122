import ReportCard from './ReportCard';

const Reports = () => {
    return (
        <div className="container">
            <h1>View a report</h1>

            <ReportCard title="Recall Report" description="View a recall report." link="/reports/recall" />
        </div>
    );
};

export default Reports;