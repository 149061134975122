import { useFormContext } from "react-hook-form";
import { calculateEggCount } from "../utils/calculateEggCount";

interface Props {
    max: number;
}

const GradingWastage = (props:Props) => {

    const { register, watch, formState: { errors } } = useFormContext()
    const units = ['Eggs', 'Dozen', 'Trays']

    let values = watch();
    let total: number = 0;

    function sum(value: string, unit: string) {
        if (typeof value !== 'undefined') {

            total += calculateEggCount(parseInt(value), unit.toLowerCase())

        
            if (isNaN(total)) {
                total = 0;
            }
        }
        return total;
    }

    return (
        <div className="item">
            <label className="required">Waste count</label>
            <p>Record the number of eggs wasted below.</p>
            {errors.waste_count && <span className="form_error">The waste count is required</span>}
                <div className="inputWithUnit">
                    <div className="input">
                    <input type="number" min="0" max={ props.max} defaultValue="0" {...register("waste_count", { required: true })} />
                    </div>
                    <div className="unit">
                        <select {...register("waste_unit")}>
                        {units.map((option, index) =>
                            <option key={index}>{option}</option>
                        )}
                        </select>
                    </div>
            </div>
            <div className="outputTools">
                <div className="flex"></div>        
                <div className="totals">
                    <h4>Total:</h4>
                    <span className="GradeTotal">{ sum(values.waste_count, values.waste_unit) }</span>
                </div>
            </div>
            </div>
    );
};

export default GradingWastage;