interface sellProps {
    sales: Array<any>;
}

const SellHistoryMeta = ({ sales }: sellProps) => {
    return (
        <p className="grademeta"><strong>Sales completed to date:</strong>  { sales.length }</p>
    );
};

export default SellHistoryMeta;