import styled from "styled-components";

interface ButtonProps {
    name: string,
    onClick: () => void;
}

const button = styled.button`
    background-color:red;
`;

const UtilityButton = ({name, onClick}:ButtonProps) => {
    return (
        <button type="button" onClick={onClick}>{ name }</button>
    );
};

export default UtilityButton;
