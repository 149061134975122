import { Link } from 'react-router-dom';
import {Bar, Line} from 'react-chartjs-2';
import CollectionHistoryMeta from './CollectionHistoryMeta';
import { calculateEggCount } from '../utils/calculateEggCount';
import { formatDate } from '../utils/formatDate';
//import React, { useEffect } from 'react';
import React from 'react';
import PrintButton from './PrintButton';
//import CSVExportButton from './CSVExportButton';
import { useForm } from "react-hook-form";

type historyForm = {
    period_start_date: string;
    period_end_date: string;
    shed: string;
}

type collectionSession = {
    date: string;
    shed: string;
    type: string;
    flock: string,
    eggs_count: number;
    eggs_unit: string;
    eggs_total: string;
    floor_count: number;
    floor_count_unit: string;
    waste_count: string;
    waste_unit: string;
    deceased_count: number;
    comment: string;
    collected_by: string;
    qty: number;
    graded_qty: number;
    storage_location: string;
    flock_age: number;
    water_consumption: number;
    feed_consumption: number;
    shed_temperature: number;
};

const CollectionHistory = () => {

    const collections = localStorage.getItem("collections") || '{}';

    let collectionsArray: collectionSession[] = [];

    try {
        collectionsArray = JSON.parse(collections);
        collectionsArray.reverse()
    } catch (e) {
        collectionsArray = [];
    }

    const collectionsArrayReversed = collectionsArray.reverse();

    //Register and set up form
    const { register, watch } = useForm<historyForm>();
    
    let watchPeriodStartDate: any = null
    let watchPeriodEndDate: any = null
    let watchShed: any = watch("shed", "All");

    if (collectionsArrayReversed.length !== 0) {
        watchPeriodStartDate = watch("period_start_date", collectionsArrayReversed[0].date);
        watchPeriodEndDate = watch("period_end_date", collectionsArrayReversed[collectionsArrayReversed.length - 1].date);
        watchShed = watch("shed", "All");
    }
    
    /* useEffect(() => {

        if (collectionsArrayReversed.length !== 0) {
            setValue('period_start_date', collectionsArrayReversed[0].date)
            setValue('period_end_date', collectionsArrayReversed[collectionsArrayReversed.length - 1].date)
        }
    }, []); */

    let CollectionsInDay = collectionsArray.filter(obj => {        
        return new Date(obj.date) >= new Date(watchPeriodStartDate) && new Date(obj.date) <= new Date(watchPeriodEndDate)
    });

    //console.log('collections in day',CollectionsInDay)

    //REMOVE
    if (collectionsArray.length > 0) {
        collectionsArrayReversed.filter((obj, index, self) =>
                                new Date(obj.date) >= new Date(watchPeriodStartDate)
                                ).map((collection: collectionSession, index: number) =>
                                console.log('date',collection.date+" "+watchPeriodStartDate)) 
    }

    let filteredCollectionArrayReversed: collectionSession[] = collectionsArrayReversed.filter((obj, index, self) =>
        new Date(obj.date) >= new Date(watchPeriodStartDate)
    );
    
    //Filter by shed
    //console.log('Watch shed',watchShed)
    if (watchShed !== "All") {
        filteredCollectionArrayReversed = filteredCollectionArrayReversed.filter((obj, index, self) => {
            return obj.shed === watchShed
        });
    }

    if (watchPeriodStartDate && watchPeriodEndDate) {
        let filterEndDate = ""
        if (new Date(watchPeriodStartDate) > new Date(watchPeriodEndDate)) {
            filterEndDate = watchPeriodStartDate
        } else {
            filterEndDate = watchPeriodEndDate
        }
        filteredCollectionArrayReversed = filteredCollectionArrayReversed.filter((obj, index, self) => {
            return new Date(obj.date) >= new Date(watchPeriodStartDate) && new Date(obj.date) <= new Date(filterEndDate)
        });
    }

    //Totals for graphs
    var total_collected = new Map();
    filteredCollectionArrayReversed.forEach((element) => {
        if (total_collected.get(element.date)) total_collected.set(element.date, total_collected.get(element.date) + Number(element.qty));
        else total_collected.set(element.date, Number(element.qty));
    });
    const total_collected_for_graph = Array.from(total_collected.values());
    //console.log(total_collected_for_graph);

    var total_graded = new Map();
    filteredCollectionArrayReversed.forEach((element) => {
        if (total_graded.get(element.date)) total_graded.set(element.date, total_graded.get(element.date) + Number(element.graded_qty));
        else total_graded.set(element.date, Number(element.graded_qty));
    });
    const total_graded_for_graph = Array.from(total_graded.values());
    //console.log(total_graded_for_graph);
    
    const collected:number[] = total_collected_for_graph.map((qty: number) => {
        return qty;
    })

    const graded:number[] = total_graded_for_graph.map((qty: number) => {
        return qty;
    })

    //Take the total eggs graded from the eggs collecetd across each day
    let balances: number[] = [];
    collected.forEach((element: number, index) => {
        let b = element - graded[index];
        if (b < 0) b = 0;
        balances.push(b);
    });

    const state = {

        labels: filteredCollectionArrayReversed.filter((obj, index, self) =>
        index === self.findIndex((t) => (
        t.date === obj.date 
        ))).map((collection: collectionSession) => {
            return collection.date;
        }),

        datasets: [
            {
                type: 'bar',
                label: 'Eggs Available',
                backgroundColor: '#f0f0f0',
                borderWidth: 1,
                borderColor: '#dfdfdf',
                tension: 0.1,
                pointRadius: 2,
                data: balances
            },
            {
                type: 'bar',
                label: 'Eggs Graded',
                backgroundColor: '#d8d8d8',
                borderWidth: 1,
                borderColor: '#dfdfdf',
                tension: 0.1,
                pointRadius: 2,
                data: graded
            }
        ]
    }

    let ts = filteredCollectionArrayReversed.filter((obj, index, self) =>
        index === self.findIndex((t) => (
            t.shed === obj.shed
        ))).map((collection: collectionSession) => {
            return collection.shed;
        });

    let td = filteredCollectionArrayReversed.filter((obj, index, self) =>
        index === self.findIndex((t) => (
            t.date === obj.date
        ))).map((collection: collectionSession) => {
            return collection.date;
        });
    
    let dataArray:any[] = [];
    ts.forEach((shed) => {
        let dayCount:any[] = []
        td.forEach((date) => {
            let dayTotalForShed = 0  
            filteredCollectionArrayReversed.forEach((collection:collectionSession) => { 
                if (collection.date === date && collection.shed === shed) {
                    dayTotalForShed += Number(collection.feed_consumption);
                }
            })
            dayCount.push(dayTotalForShed);
        }) 
        dataArray.push({ 'shed': shed, 'count': dayCount } )
    });
    //console.log('dataArray', dataArray);

    function random_rgba() {
        var o = Math.round, r = Math.random, s = 255;
        return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ')';
    }

    let data:any[] = []
    dataArray.forEach((valueArray, key) => {
        data.push({ type: 'line', label: valueArray.shed, borderWidth: 2, borderColor: random_rgba(), data: valueArray.count })
    });

    const feed_plot = {

        labels: filteredCollectionArrayReversed.filter((obj, index, self) =>
        index === self.findIndex((t) => (
        t.date === obj.date 
        ))).map((collection: collectionSession) => {
            return collection.date;
        }),
        
        datasets: data
    }


    //Water Plot

    dataArray = [];
    ts.forEach((shed) => {
        let dayCount:any[] = []
        td.forEach((date) => {
            let dayTotalForShed = 0  
            filteredCollectionArrayReversed.forEach((collection:collectionSession) => { 
                if (collection.date === date && collection.shed === shed) {
                    dayTotalForShed += Number(collection.water_consumption);
                }
            })
            dayCount.push(dayTotalForShed);
        }) 
        dataArray.push({ 'shed': shed, 'count': dayCount } )
    });

    data = []
    dataArray.forEach((valueArray, key) => {
        data.push({ type: 'line', label: valueArray.shed, borderWidth: 2, borderColor: random_rgba(), data: valueArray.count })
    });

    const water_plot = {

        labels: filteredCollectionArrayReversed.filter((obj, index, self) =>
        index === self.findIndex((t) => (
        t.date === obj.date 
        ))).map((collection: collectionSession) => {
            return collection.date;
        }),
        
        datasets: data
    }

    //Temp plot

    dataArray = [];
    ts.forEach((shed) => {
        let dayCount:any[] = []
        td.forEach((date) => {
            let dayTotalForShed = 0  
            filteredCollectionArrayReversed.forEach((collection:collectionSession) => { 
                if (collection.date === date && collection.shed === shed) {
                    dayTotalForShed += Number(collection.shed_temperature);
                }
            })
            dayCount.push(dayTotalForShed);
        }) 
        dataArray.push({ 'shed': shed, 'count': dayCount } )
    });

    data = []
    dataArray.forEach((valueArray, key) => {
        data.push({ type: 'line', label: valueArray.shed, borderWidth: 2, borderColor: random_rgba(), data: valueArray.count })
    });

    const temp_plot = {

        labels: filteredCollectionArrayReversed.filter((obj, index, self) =>
        index === self.findIndex((t) => (
        t.date === obj.date 
        ))).map((collection: collectionSession) => {
            return collection.date;
        }),
        
        datasets: data
    }

    //DeadBirds Plot

    dataArray = [];
    ts.forEach((shed) => {
        let dayCount:any[] = []
        td.forEach((date) => {
            let dayTotalForShed = 0  
            filteredCollectionArrayReversed.forEach((collection:collectionSession) => { 
                if (collection.date === date && collection.shed === shed) {
                    dayTotalForShed += Number(Number(collection.deceased_count));
                }
            })
            dayCount.push(dayTotalForShed);
        }) 
        dataArray.push({ 'shed': shed, 'count': dayCount } )
    });

    data = []
    dataArray.forEach((valueArray, key) => {
        data.push({ type: 'line', label: valueArray.shed, borderWidth: 2, borderColor: random_rgba(), data: valueArray.count })
    });

    const deceased_birds_plot = {

        labels: filteredCollectionArrayReversed.filter((obj, index, self) =>
        index === self.findIndex((t) => (
        t.date === obj.date 
        ))).map((collection: collectionSession) => {
            return collection.date;
        }),
        
        datasets: data
    }

    //Floor count Plot

    dataArray = [];
    ts.forEach((shed) => {
        let dayCount:any[] = []
        td.forEach((date) => {
            let dayTotalForShed = 0  
            filteredCollectionArrayReversed.forEach((collection:collectionSession) => { 
                if (collection.date === date && collection.shed === shed) {
                    dayTotalForShed += Number(calculateEggCount(Number(collection.floor_count), collection.floor_count_unit));
                }
            })
            dayCount.push(dayTotalForShed);
        }) 
        dataArray.push({ 'shed': shed, 'count': dayCount } )
    });

    data = []
    dataArray.forEach((valueArray, key) => {
        data.push({ type: 'line', label: valueArray.shed, borderWidth: 2, borderColor: random_rgba(), data: valueArray.count })
    });

    const floor_count_plot = {

        labels: filteredCollectionArrayReversed.filter((obj, index, self) =>
        index === self.findIndex((t) => (
        t.date === obj.date 
        ))).map((collection: collectionSession) => {
            return collection.date;
        }),
        
        datasets: data
    }

    if (collectionsArray.length > 0) {
        return (
            <div className="container">
                <h1>Collection history<span> <Link to="/collection">Make a collection</Link></span></h1>
                <CollectionHistoryMeta collections={ collectionsArray } />
                <form>

                    <h3>History</h3>
                    
                    <div>
                    <label>Shed:</label>
                        <select className="resetLength" {...register("shed", { required: true })}>
                            <option key="all" value="All">All</option>
                        {collectionsArray.filter((obj, index, self) =>
                            index === self.findIndex((o) => (
                                o.shed === obj.shed 
                                ))
                            ).map((collection: collectionSession, index: number) =>
                            <option key={index} value={collection.shed}>{collection.shed}</option>
                        )}
                    </select>
                    </div>

                    <div className="history_date_range">
                    <label>Date of collection:</label>
                    <select className="resetLength" {...register("period_start_date", { required: true })}>
                        {
                                collectionsArrayReversed.filter((obj, index, self) =>
                                index === self.findIndex((t) => (
                                t.date === obj.date 
                                ))).map((collection: collectionSession, index) => {
                                   return <option key={index} value={collection.date}>{formatDate(collection.date)}</option>
                                })
                        }
                        </select>
                        
                        &nbsp;to&nbsp; 

                    <select className="resetLength" {...register("period_end_date", { required: true })}>
                        {
                                collectionsArrayReversed.filter((obj, index, self) =>
                                index === self.findIndex((t) => (
                                t.date === obj.date
                                ))).map((collection: collectionSession, index) => {
                                    if (new Date(collection.date) >= new Date(watchPeriodStartDate)) {
                                        return <option key={index} value={collection.date}>{formatDate(collection.date)}</option>
                                    } 
                                    return ""
                                })
                        }
                            
                    </select>
                    </div>    

                    <div className="standfirstPlot">
                    <div className="plot_container">
                        <h4>{ watchShed } Shed Performance</h4>
                        <Bar
                            data={state}
                            options={{
                                title: {
                                    display: true,
                                    text: 'Daily egg count from collections',
                                    fontSize: 20
                                },
                                legend: {
                                    display: true,
                                    position: 'right'
                                },
                                scales: {
                                    x: {
                                        stacked: true
                                    },
                                    y: {
                                        stacked: true
                                    }
                                }
                            }}
                        />
                        </div>
                    </div>

                    <div className="secondary_plot_container">
                    <div className="plot_container">
                    <h4>Feed Intake</h4>
                    <div className="plot">
                            <Line
                                data={feed_plot}
                                options={{
                                    title: {
                                        display: true,
                                        text: 'Daily egg count from collections',
                                        fontSize: 20
                                    },
                                    legend: {
                                        display: true,
                                        position: 'right'
                                    },
                                    scales: {
                                        y: {
                                            beginAtZero: true
                                        }
                                    }
                                }}
                            />
                            </div>
                            <p className="endnote">This plot shows the total amount of feed consumed at<br/>time of collection over time.</p>
                        </div>
                        
                        <div className="plot_container">
                            <h4>Water Intake</h4>
                            <div className="plot">
                                <Line
                                    data={water_plot}
                                    options={{
                                        title: {
                                            display: true,
                                            text: 'Daily egg count from collections',
                                            fontSize: 20
                                        },
                                        legend: {
                                            display: true,
                                            position: 'right'
                                        },
                                        scales: {
                                            y: {
                                                beginAtZero: true
                                            }
                                        }
                                    }}
                                />
                            </div>   
                            <p className="endnote">This plot shows the total water consumed at time<br/>of collection over time.</p>
                        </div>

                        <div className="plot_container">
                        <h4>Shed Temperature (ºC)</h4>
                        <div className="plot">
                                <Line
                                    data={temp_plot}
                                    options={{
                                        title: {
                                            display: true,
                                            text: 'Daily egg count from collections',
                                            fontSize: 20
                                        },
                                        legend: {
                                            display: true,
                                            position: 'right'
                                        },
                                        scales: {
                                            y: {
                                                beginAtZero: true
                                            }
                                        }
                                    }}
                                />
                                </div>
                                <p className="endnote">This plot shows the temperature in the shed(s)<br/>recorded at time of collection over time.</p>
                            </div>

                        <div className="plot_container">
                        <h4>Deceased Birds</h4>
                        <div className="plot">
                                <Line
                                    data={deceased_birds_plot}
                                    options={{
                                        title: {
                                            display: true,
                                            text: 'Daily egg count from collections',
                                            fontSize: 20
                                        },
                                        legend: {
                                            display: true,
                                            position: 'right'
                                        },
                                        scales: {
                                            y: {
                                                beginAtZero: true
                                            }
                                        }
                                    }}
                                />
                                </div>
                                <p className="endnote">This plot shows the total number of deceased birds<br/>recorded at time of collection over time.</p>
                            </div>

                        <div className="plot_container">
                        <h4>Floor Count</h4>
                        <div className="plot">
                                <Line
                                    data={floor_count_plot}
                                    options={{
                                        title: {
                                            display: true,
                                            text: 'Daily egg count from collections',
                                            fontSize: 20
                                        },
                                        legend: {
                                            display: true,
                                            position: 'right'
                                        },
                                        scales: {
                                            y: {
                                                beginAtZero: true
                                            }
                                        }
                                    }}
                                />
                                </div>
                                <p className="endnote">This plot shows the total number of floor eggs<br/>collected over time.</p>
                            </div>
                        

                    </div>

                    <div className="collectionHistoryEntry">
                    
                        <h4>Collections in period {watchPeriodStartDate} - {watchPeriodEndDate}</h4>
                        <table>
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Shed</th>
                            <th>Type</th>
                            <th>Collected By</th>
                            <th>Eggs</th>
                            <th>Floor</th>
                            <th>Total</th>
                            <th>Waste</th>
                            <th>Graded</th>    
                                </tr>
                            </thead>
                        <tbody>
                        {
                       filteredCollectionArrayReversed.map((obj, index) => 
                            <React.Fragment key={index}>       
                            <tr>
                                <td>{obj.date}</td>
                                <td>{obj.shed}</td>
                                <td>{ obj.type }</td>
                                <td>{ obj.collected_by }</td>
                                <td>{ calculateEggCount(Number(obj.eggs_count), obj.eggs_unit)}</td>
                                <td>{ calculateEggCount(Number(obj.floor_count), obj.floor_count_unit) }</td>
                                <td>{ calculateEggCount(Number(obj.eggs_count), obj.eggs_unit) + calculateEggCount(Number(obj.floor_count), obj.floor_count_unit) }</td>
                                <td>{ calculateEggCount(Number(obj.waste_count), obj.waste_unit) }</td>
                                <td>{obj.graded_qty}</td>
                                </tr>
                            </React.Fragment> 
                            )
                        }
                        <tr className="totals">
                            <td>Totals</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{ CollectionsInDay.reduce((total, entry) => total + calculateEggCount(Number(entry.eggs_count), entry.eggs_unit), 0) } </td>
                            <td>{ CollectionsInDay.reduce((total, entry) => total + calculateEggCount(Number(entry.floor_count), entry.floor_count_unit), 0) } </td>
                            <td>{ CollectionsInDay.reduce((total, entry) => total + calculateEggCount(Number(entry.eggs_count), entry.eggs_unit), 0) + CollectionsInDay.reduce((total, entry) => total + calculateEggCount(Number(entry.floor_count), entry.floor_count_unit), 0)}</td>
                            <td>{ CollectionsInDay.reduce((total, entry) => total + calculateEggCount(Number(entry.waste_count), entry.waste_unit), 0) }</td>
                            <td>{ CollectionsInDay.reduce((total, entry) => total + Number(entry.graded_qty), 0)}</td>
                            </tr>
                            </tbody>
                    </table>

                        <PrintButton />
                        {/*<CSVExportButton />*/}
                    </div>
                </form>
            </div>
        );
    } else {
        return (
            <div className="container">
                <h1>Collection history<span> <Link to="/collection">Make a new collection</Link></span></h1>
        
                <CollectionHistoryMeta collections={collectionsArray} />
                <form>
                    <h2>There is no collection history...</h2>
                    <p>You haven't made any collections yet. <Link to="/collection">You can enter a collection here.</Link> </p>
                </form>
            </div>
        );
    }
}

export default CollectionHistory;