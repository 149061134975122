import { Link } from 'react-router-dom';
import SellHistoryMeta from './SellHistoryMeta';
import { calculateEggCount } from '../utils/calculateEggCount';
import { useState } from 'react';
import React from 'react';
import PrintButton from './PrintButton';
import { formatDate } from '../utils/formatDate';

type SoldProduct = {
    brand: string;
    type: string;
    qty: number;
    unit: string;
}
  
type SalesSession = {
    date: string;
    customer: string;
    invoice_number: string;
    po_number: string;
    products: SoldProduct[];
    comment: string;
};

const SellHistory = () => {

    let total: number = 0;
    function sum(values: SoldProduct[]) {
        total = 0;
        if (typeof values !== 'undefined') {

            values.forEach((row: SoldProduct) => {
                total += calculateEggCount(row.qty, row.unit.toLowerCase())
            });

        
            if (isNaN(total)) {
                total = 0;
            }
        }
        return total;
    }

    const sales = localStorage.getItem("sales") || '{}';

    const [selected,setSelected] = useState('0');
    const selectChanged = (event: string) => {
        setSelected(event);
    };
    
    let salesArray: SalesSession[] = [];
    let comment: string = 'n/a';

    try {
        salesArray = JSON.parse(sales);
        salesArray.reverse()
        if (salesArray[parseInt(selected)].comment !== '') {
            comment = salesArray[parseInt(selected)].comment
        }
    } catch (e) {
        salesArray = [];
    }

    if (salesArray.length > 0) {
    return (
        <div className="container">
            <h1>Sales history<span> <Link to="/sell">Log a sale</Link></span></h1>
        
            <SellHistoryMeta sales={ salesArray } />
            <form>
                <select value={selected} onChange={e => selectChanged(e.target.value)}>
                    { salesArray.map((sale: SalesSession, index: number) =>
                        <option key={ index } value={ index }>{ formatDate(sale.date) }</option>
                    ) }
                </select>
                
                <div className="saleHistoryEntry">
                    <h2>Transfer Information</h2>
                    <ul className="saleHistoryMeta">
                        <li><span>Date of sale:</span> { salesArray[parseInt(selected)].date }</li>
                        <li><span>Customer:</span> {salesArray[parseInt(selected)].customer}</li>
                        <li><span>PO number:</span> { salesArray[parseInt(selected)].po_number }</li>
                        <li><span>Invoice number:</span> { salesArray[parseInt(selected)].invoice_number }</li>
                    </ul>

                    <h3>Product sold</h3>
                    <div className="sold">
                        <div className="header">Brand</div>
                        <div className="header">Type</div>
                        <div className="header">Unit</div>
                        <div className="header">Quantity</div>
                    { salesArray[parseInt(selected)].products.map((product: SoldProduct, index: number) =>
                        <React.Fragment key={index}>
                        <div>{ product.brand }</div>
                        <div>{ product.type }</div>
                        <div>{ product.unit }</div>
                        <div>{ product.qty }</div>
                        </React.Fragment>
                        )}
                        <div className="totalRow"></div>
                        <div className="totalRow"></div>
                        <div className="totalRow total">Total</div>
                        <div className="totalRow totalValue">{ sum(salesArray[parseInt(selected)].products) }</div>
                    </div>
                    </div>
                
                    <div className="notes"><h3>Notes</h3>
                    { comment }
                </div>
                <PrintButton />
                {/*<CSVExportButton />*/}
            </form>
        </div>
    );
    } else {
    return (
        <div className="container">
            <h1>Sales history<span> <Link to="/sell">Log a sale</Link></span></h1>
    
            <SellHistoryMeta sales={salesArray} />
            <form>
                <h2>There is no sales history...</h2>
                <p>You haven't entered any sales yet. <Link to="/sell">You can enter a sale here.</Link> </p>
            </form>
        </div>
    );
}
};

export default SellHistory;

