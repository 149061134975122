import { Link } from 'react-router-dom';
import TransferHistoryMeta from './TransferHistoryMeta';
import { calculateEggCount } from '../utils/calculateEggCount';
import { useState } from 'react';
import React from 'react';
import PrintButton from './PrintButton';
import { formatDate } from '../utils/formatDate';

type TransferedProduct = {
    shed: string;
    type: string;
    esa_compliant: string;
    qty: number;
    unit: string;
}
  
type TransferSession = {
    date: string;
    operator: string;
    transfer_type: string;
    transfer_from: string;
    transfer_to: string;
    pallet_id: string;
    transfers: TransferedProduct[];
    comment: string;
};

const TransferHistory = () => {

    let total: number = 0;
    function sum(values: TransferedProduct[]) {
        total = 0;
        if (typeof values !== 'undefined') {

            values.forEach((row: TransferedProduct) => {
                total += calculateEggCount(row.qty, row.unit.toLowerCase())
            });

        
            if (isNaN(total)) {
                total = 0;
            }
        }
        return total;
    }

    const transfers = localStorage.getItem("transfers") || '{}';

    const [selected,setSelected] = useState('0');
    const selectChanged = (event: string) => {
        setSelected(event);
    };

    let transfersArray: TransferSession[] = [];
    let comment: string = 'n/a';
    
    try {
        transfersArray = JSON.parse(transfers);
        transfersArray.reverse()
        if (transfersArray[parseInt(selected)].comment !== '') {
            comment = transfersArray[parseInt(selected)].comment
        }
    } catch (e) {
        transfersArray = [];
    }

    if (transfersArray.length > 0) {
        return (
            <div className="container">
                <h1>Transfer history<span> <Link to="/transfer">Perform a transfer</Link></span></h1>
        
                <TransferHistoryMeta transfers={transfersArray} />
                <form>
                    <select value={selected} onChange={e => selectChanged(e.target.value)}>
                        {transfersArray.map((transfer: TransferSession, index: number) =>
                            <option key={index} value={index}>{formatDate(transfer.date)}</option>
                        )}
                    </select>
                
                    <div className="transferHistoryEntry">
                        <h2>Transfer Information</h2>
                        <ul className="transferHistoryMeta">
                            <li><span>Transfer Date:</span> {transfersArray[parseInt(selected)].date}</li>
                            <li><span>Operator:</span> {transfersArray[parseInt(selected)].operator}</li>
                            <li><span>Transfer Type:</span> {transfersArray[parseInt(selected)].transfer_type}</li>
                            <li><span>Transfer From:</span> {transfersArray[parseInt(selected)].transfer_from}</li>
                            <li><span>Transfer To:</span> {transfersArray[parseInt(selected)].transfer_to}</li>
                            <li><span>Pallet Identifier:</span> {transfersArray[parseInt(selected)].pallet_id}</li>
                        </ul>

                        <h3>Product moved</h3>
                        <div className="incoming">
                            <div className="header">Shed</div>
                            <div className="header">Type</div>
                            <div className="header">ESA Compliant</div>
                            <div className="header">Unit</div>
                            <div className="header">Quantity</div>
                            {transfersArray[parseInt(selected)].transfers.map((transfer: TransferedProduct, index: number) =>
                                <React.Fragment key={index}>
                                    <div>{transfer.shed}</div>
                                    <div>{transfer.type}</div>
                                    <div>{transfer.esa_compliant}</div>
                                    <div>{transfer.unit}</div>
                                    <div>{transfer.qty}</div>
                                </React.Fragment>
                            )}
                            <div className="totalRow"></div>
                            <div className="totalRow"></div>
                            <div className="totalRow"></div>
                            <div className="totalRow total">Total</div>
                            <div className="totalRow totalValue">{sum(transfersArray[parseInt(selected)].transfers)}</div>
                        </div>
                    </div>
                        
                    <div className="notes"><h3>Notes</h3>
                    {comment}
                    </div>
                    
                    <PrintButton />
                    {/*<CSVExportButton />*/}
                </form>
            </div>
        );
    } else {
        return (
            <div className="container">
                <h1>Transfer history<span> <Link to="/transfer">Perform a transfer</Link></span></h1>
        
                <TransferHistoryMeta transfers={transfersArray} />
                <form>
                    <h2>There is no transfer history...</h2>
                    <p>You haven't completed any transfers yet. <Link to="/grade">You can enter a transfer here.</Link> </p>
                </form>
            </div>
        );
    }
};

export default TransferHistory;


