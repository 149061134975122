export function calculateEggCount(count: number, unit: string) {
    
    let m: number = 0;
    let total: number = 0;

    if (typeof unit !== 'undefined') {
        switch (unit.toLowerCase()) {
            case 'eggs':
                m = 1;
                break;
            case 'dozen':
                m = 12;
                break;
            case 'trays':
                m = 30;
                break;
            default:
                m = 1;
        }

        total += count * m
    }
    return total;
}