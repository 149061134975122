import { useFormContext, useFieldArray } from "react-hook-form";
import { calculateEggCount } from "../utils/calculateEggCount";
import UtilityButton from "./UtilityButton";


interface transferEntry {
    shed: string;
    type: string;
    esa_compliant: string;
    qty: number;
    unit: string;
}

const TransferContentRow = () => {

    const { register, control, watch, formState: { errors } } = useFormContext()

    const { fields, append, remove } = useFieldArray({
        control,
        name: "transfers"
    });

    let values = watch();

    const time_now = new Date();
    var day = ("0" + time_now.getDate()).slice(-2);
    var month = ("0" + (time_now.getMonth() + 1)).slice(-2);
    var today = time_now.getFullYear()+"-"+(month)+"-"+(day) 

    const esa_compliant_options = ['Yes', 'No']
    const unit = ['Eggs', 'Dozen', 'Trays']

    let total: number = 0;

    function sum(values: transferEntry[]) {
        if (typeof values !== 'undefined') {

            values.forEach((row: transferEntry) => {
                if (typeof row.qty !== 'undefined' && typeof row.unit !== 'undefined') { 
                    total += calculateEggCount(row.qty, row.unit.toLowerCase())
                }
            });

        
            if (isNaN(total)) {
                total = 0;
            }
        }
        
        return total;
    }
    
    const addRowClick = () => {
        if (fields.length < 5) {
            append({ "shed": "Shed", "type": "AAA", "laying_date": today, "supplier": "supplier", "qty": 0, "unit":"Eggs" });
        }
    };

    return (
        <>
        <div className="items">
            <div className="shed">
                <label className="required">Shed</label>
            </div>

            <div className="type">
            <label className="required">Type/Size</label>
            </div>

            <div className="ESA-Compliant">
                <label className="required">ESA-Compliant</label>   
            </div>

            <div className="number"> 
                <label className="required">Quantity</label>
            </div>
                
            <div className="number"> 
                <label className="required">Unit</label>
            </div>
            <div></div>
        </div>
        
        {fields.map((data, index) => {
                return (
                    <div className="items" key={ index }>
                        <div className="shed">
                            <input type="text" {...register(`transfers.${index}.shed` as const, { required: true })} className={ errors?.['transfer']?.[index]?.['shed'] ? "highlight" : '' } />
                        </div>

                        <div className="type">
                            <input type="text" {...register(`transfers.${index}.type` as const, { required: true })} className={ errors?.['transfer']?.[index]?.['type'] ? "highlight" : '' } />
                        </div>

                        <div className="ESA-Compliant">
                            <select {...register(`transfers.${index}.esa_compliant` as const, { required: true })} >
                            { esa_compliant_options.map((option, index) =>
                                <option value={ option } key={index}>{option}</option>
                            )}
                            </select>   
                        </div>

                        <div className="number"> 
                            <input type="number" min="0" defaultValue="0" {...register(`transfers.${index}.qty` as const, { required: true })} className={ errors?.['transfer']?.[index]?.['qty'] ? "highlight" : '' } />
                        </div>

                        <div className="unit">
                            <select {...register(`transfers.${index}.unit` as const, { required: true })} className={ errors?.['transfer']?.[index]?.['unit'] ? "highlight" : '' } >
                            {unit.map((option, index) =>
                                <option value={ option } key={index}>{option}</option>
                            )}
                            </select>   
                        </div>
                        {index>0 && 
                        <div><button className="close" type="button" onClick={() => index > 0 ? remove(index) : {}}>x</button></div>
                        }
                    </div>
                );
        })}
            {errors.transfers && <span className="form_error">Please ensure all required fields are completed.</span>}
                    <div className="transferTools">
                        <div className="addRowControl">
                            <UtilityButton name="Add row" onClick={addRowClick} />
                        </div>
                        <div className="flex"></div>
                        <div className="totals">
                            <h4>Total:</h4>
                            <span className="GradeTotal">{ sum(values.transfers) }</span>
                        </div>
                    </div> 
            </>
    );
};

export default TransferContentRow;