import { useEffect } from 'react';
import { useStateWithLocalStorage } from '../utils/useStateWithLocalStorage';
import StatBox from './StatBox';

function greeting() {
    const  cDate = new Date();
    const hrs = cDate.getHours();
    let greeting: String = '';

    if (hrs < 12)
        greeting = 'Good morning';
    else if (hrs >= 12 && hrs <= 17)
        greeting = 'Good afternoon';
    else if (hrs >= 17 && hrs <= 24)
        greeting = 'Good evening';
    
    return greeting;
}

/*
var localStorageClear = function() {
    if(window.confirm('Do you really want to delete all ' + localStorage.length + ' localStorage items of this website?')) {
      localStorage.clear();
    }
}
*/

function download(content:Storage, fileName:string, contentType:string) {
    var a = document.createElement("a");
    var file = new Blob([JSON.stringify(content)], {type: contentType});
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
}

function readJSON(file:File) {
    if (file.type && !file.type.startsWith('application/json')) {
        console.log('This is not a JSON file');
        return;
    }

    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
        const appJSON = (event.target as FileReader).result;
        const final = JSON.parse(appJSON! as string);
        
        //Clear
        localStorage.clear();

        //Load items into local storage
        for (var key in final){
            var value = decodeURIComponent(decodeURI(final[key]));
            window.localStorage.setItem(key, value);
            //console.log(key, value)
        }
    });
    reader.readAsText(file)
}

const Start = () => {
    const [collections] = useStateWithLocalStorage(
        'collections'
    );
    const [gradings] = useStateWithLocalStorage(
        'gradings'
    );
    const [transfers] = useStateWithLocalStorage(
        'transfers'
    );
    const [sales] = useStateWithLocalStorage(
        'sales'
    );
    const collectionCount = collections!== '' ?  JSON.parse(collections).length : 0;
    const gradingCount = gradings!== '' ?  JSON.parse(gradings).length : 0;
    const transferCount = transfers !== '' ? JSON.parse(transfers).length : 0;
    const salesCount = sales!== '' ? JSON.parse(sales).length : 0;

    useEffect(() => {
        const fileSelector = document.getElementById('file-selector');
        fileSelector!.addEventListener('change', (event) => {
            let isAccepted = window.confirm("Are you sure you want to load this file? This cannot be undone so please make sure you have a back up of your data.");
            if (isAccepted) {
                const fileList = (event.target as HTMLInputElement).files;
                //console.log("filelist: ",fileList);
                readJSON(fileList![0]!)
            }
        });
    }, []);

    return (
        <div className="container">
            <h1>{ greeting() }.<span>What would you like to do?</span></h1>
            <div className="stat_board">
                <StatBox title="Collections" value={ collectionCount } since_date={collectionCount >= 1 ? "Since: "+JSON.parse(collections)[0].date : ''} link="/collection" history_link="/collection/history" link_text="Log a collection" size="single" ></StatBox>
                <StatBox title="Grading Sessions" value={gradingCount} since_date={gradingCount >= 1 ? "Since: "+JSON.parse(gradings)[0].start_date : ''} link="/grade" history_link="/grading/history" link_text="Grade some eggs" size="single" ></StatBox>
                <StatBox title="Transfers" value={transferCount} since_date={transferCount >= 1 ? "Since: "+JSON.parse(transfers)[0].date : ''} link="/transfer" history_link="/transfer/history" link_text="Transfer some eggs" size="single" ></StatBox>
                <StatBox title="Sales" value={salesCount} since_date={salesCount >= 1 ?  "Since: "+JSON.parse(sales)[0].date : ''} link="/sell" history_link="/sell/history" link_text="Sell some eggs" size="single" ></StatBox>
            </div>

            <div className="file_utils">
                <h3>Sync Content</h3>
                <p>You can sync content between devices by downloading and uploading your content using the buttons below.</p>
                <button onClick={() => download(localStorage, 'json.json', 'application/json')}>Download</button> or <input type="file" id="file-selector" className="upload_file_input" title=" " accept=".json" />
            </div>
        </div>
    );
};

export default Start